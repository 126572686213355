var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "customerFilter",
        {
          ref: "customerFilter",
          attrs: { filter: _vm.filter, queryType: _vm.queryType }
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleQuery()
                }
              }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabs-container" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.typeChange },
              model: {
                value: _vm.queryType,
                callback: function($$v) {
                  _vm.queryType = $$v
                },
                expression: "queryType"
              }
            },
            _vm._l(_vm.tabs, function(tab) {
              return _c("el-tab-pane", {
                key: tab.key,
                attrs: { name: tab.key, label: tab.name }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-content" },
        [
          _c("customerList", {
            ref: "customerList",
            attrs: { filter: _vm.filter }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }